<template>
  <div class="d-flex flex-column">
    <b-row
      v-if="totalGifCode > 0"
      class="mb-2"
    >
      <b-col>
        <gif-code-notification />
      </b-col>
    </b-row>
    <b-row class="sm-section bg-white d-flex mx-0 mb-2">
      <b-col
        v-if="isTikTokAppCenter"
        md="4"
        class="sm-card p-2"
      >
        <p class="text-secondary description">
          {{ $t('balance.textBalancePlatform', { platformName: platformName }) }}
        </p>
        <b-spinner
          v-if="loading"
          variant="secondary"
          small
        />
        <h2
          v-else
          class="mb-0 sm-value font-weight-normal text-money"
        >
          ${{ userBalance }}
          <span class="font-14 font-weight-normal">USD</span>

          <img
            v-b-tooltip.hover.top.v-light="!$device.mobile && !$device.ipad ? $t('stepGuideControl.textAddFund') : ''"
            :src="require('@/assets/images/common/ic-add-fund-red.svg')"
            alt="add-fund"
            class="cursor-pointer ml-1"
            @click="goToAddFundFromTikTok"
          >
        </h2>
      </b-col>
      <b-col
        md="4"
        class="sm-card p-2"
      >
        <p class="text-secondary description">
          {{ $t('home.summaryOverview.titleTodaySpend') }}
        </p>
        <b-spinner
          v-if="userReportLoading"
          variant="secondary"
          small
        />
        <h2
          v-else
          class="mb-0 sm-value font-weight-normal text-money"
        >
          ${{ spentToday }}
          <span class="font-14 font-weight-normal">USD</span>
        </h2>
      </b-col>
      <!-- v-if="checkHasEarnReferral && refMode" -->
      <b-col
        v-if="showFieldEarnReferral && isEcomdyPlatform"
        md="4"
        class="p-2 sm-card"
      >
        <p class="text-secondary description">
          {{ $t('home.summaryOverview.titleEarnReferral') }}
        </p>
        <b-spinner
          v-if="userInfoLoading"
          variant="secondary"
          small
        />
        <h2
          v-else
          class="mb-0 sm-value font-weight-normal earn-referral"
        >
          {{ currencyFormat(receivedRefMoney) }}<span>/</span>
          <span class="font-14 font-weight-normal">{{ countUsed }} {{ $t('home.summaryOverview.textMember') }}</span>
        </h2>
      </b-col>
      <b-col
        v-if="isSMBAgencyPlatform || isDC3Platform"
        md="4"
        class="sm-card p-2"
      >
        <p class="text-secondary description title-bc-id">
          {{ $t('home.summaryOverview.exchangeRate') }}
        </p>
        <h2
          id="testing-code"
          class="mb-0 bc-id d-flex align-items-center"
        >
          1 USD = {{ localStringFormat(USDToVNDRate) }} VNĐ
        </h2>
      </b-col>
      <b-col
        v-if="isEmediaPlatform"
        md="4"
        class="sm-card p-2"
      >
        <p class="text-secondary description title-bc-id">
          {{ $t('home.summaryOverview.titleTotalSpend') }}
        </p>
        <b-spinner
          v-if="userReportLoading"
          variant="secondary"
          small
        />
        <h2
          v-else
          class="mb-0 sm-value font-weight-normal earn-referral"
        >
          ${{ totalSpending }}
          <span class="font-14 font-weight-normal">USD</span>
        </h2>
      </b-col>
      <b-col
        md="3"
        class="sm-card p-2"
      >
        <p class="text-secondary description title-number-account">
          {{ $t('home.summaryOverview.titleTotalAdsAccount') }}
        </p>
        <h2
          class="mb-0 sm-value number-account"
        >
          {{ totalAccount }}
          <span>{{ $t('home.summaryOverview.textAdAccount') }}</span>
        </h2>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow, BCol,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import envMixin from '@/mixins/envMixin'
import _isEmpty from 'lodash/isEmpty'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import _get from 'lodash/get'
import GifCodeNotification from './GifCodeNotification.vue'

const { mapGetters: mapGettersGifCode } = createNamespacedHelpers('giftCode')
const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    GifCodeNotification,
    BRow,
    BCol,
    BSpinner,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [numberFormatMixin, envMixin, generalConfigsMixin],
  props: {
    hasReferralTracking: {
      type: Object,
      required: true,
      default: () => {},
    },
    spentToday: {
      type: Number || String,
      required: true,
      default: 0,
    },
    totalSpending: {
      type: Number || String,
      default: 0,
    },
    userReportLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    userInfoLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    countUsed: {
      type: Number || String,
      required: true,
      default: 0,
    },
    receivedRefMoney: {
      type: Number || String,
      required: true,
      default: 0,
    },
    totalAccount: {
      type: Number,
      required: true,
      default: 0,
    },
    partnerBcId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      message: '',
      copySuccess: false,
      showFieldEarnReferral: false,
    }
  },

  computed: {
    ...mapGettersGifCode(['listGiftCodePartner']),
    ...mapGetters(['user', 'isTikTokAppCenter']),

    totalGifCode() {
      return this.listGiftCodePartner?.paging?.total || 0
    },

    checkHasEarnReferral() {
      return !_isEmpty(this.hasReferralTracking)
    },

    loading() {
      return _get(this.user, ['loading'], false)
    },

    userBalance() {
      const amount = _get(this.user, ['data', 'balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data) {
          const currentCreatedAt = new Date(userInfo?.data?.createdAt)
          const releasedDate = new Date('2024-03-18T00:00:00.001Z')
          this.showFieldEarnReferral = currentCreatedAt < releasedDate
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handleCoppy() {
      window.navigator.clipboard.writeText(this.partnerBcId)
        .then(() => {
          this.copySuccess = true
        })
      setTimeout(() => {
        this.copySuccess = false
      }, 5000)
      // .catch(e => {
      //   console.error(e)
      // })
    },

    goToAddFundFromTikTok() {
      const idToken = localStorage.getItem('id_token')
      if (this.noPlan) {
        window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
      } else if (this.isOnlyEcomdyPlatform) {
        window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?tab=paypal&id_token=${idToken}`)
      } else {
        window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?id_token=${idToken}`)
      }
    },
  },
}
</script>
<style lang="scss">
  /*.progress-bar{*/
  /*  background-color: #7AB34C !important;*/
  /*}*/

  /*.progress-bar-success{*/
  /*  background-color: #F5FBF0 !important;*/
  /*}*/

  /*.sm-section{*/
  /*  margin-bottom: 24px;*/
  /*}*/
</style>

<style lang="scss" scoped>
  .sm-section{
    border-radius: var(--border-radius-sm);
    width: 100%;

    @media (max-width: 767px) {
      padding-bottom: 1rem;
    }

    .sm-value{
      font-size: 32px;
    }

    .text-money{
      color:var(--text-money);

      span{
        color:#16213e;
      }
    }

    .earn-referral{
      color:#1DB9C3;

      span{
        color:#16213e;
      }
    }

    .title-bc-id, .title-number-account{
      margin-bottom: 1.2rem;
    }

    .bc-id, .number-account{
      font-size: 16px;
      font-weight: normal;
    }

    p{
      margin-bottom: 8px;
    }

    .icon-copy{
      width: 1rem;
      height: 1rem;
      margin-left: 5px;
    }

    .divider {
      height: 100%;
      width: 1px;
      background: rgba(22, 33, 62, 0.1);
      margin: 0;
    }

    .sm-card{
      @media (max-width: 767px) {
        padding-bottom: 0 !important;
      }
    }
  }

</style>
